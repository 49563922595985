import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../Image';
import Row from '../Row';
import FloorballEvents from './FloorballEvents';
import FlooerballHeader from './FloorballHeader';
import FloorballLineups from './FloorballLineups';
import useWebSocket from 'react-use-websocket';


const LiveMatch = ({ orgMatch, mobile }) => {

    const { sendMessage, lastMessage } = useWebSocket('wss://nchan.torneopal.com/salibandy/' + orgMatch.match_id, {
        onOpen: () => console.log('ws opened'),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });
    const [match, setMatch] = useState()
    const [liveTimer, setLiveTimer] = useState(0)
    const [timeInterval, setTimeInterval] = useState(null);
    const timerOn = useRef()

    const getSecondsFromMatch = (game) => {
        if (game?.live_timer_on === "1") {
            startTimer();
        } else {
            pauseTimer();
        }
        //const dateString = "1970-01-01T00:" + (game?.live_time_mmss === "" ? game?.live_time : game?.live_time_mmss) + 'z';
        const timestring = game?.live_time_mmss === "" ? game?.live_time : game?.live_time_mmss;
        try {
            /* const date = new Date(dateString)
            const seconds = Math.floor(date / 1000) + (game.live_period - 1) * 20 * 60;
            console.log("date & seconds", dateString, date, seconds) */
            const splitted = timestring.split(":")
            const seconds = Number(splitted[0]) * 60 + Number(splitted[1]) + (game.live_period - 1) * 20 * 60;
            setLiveTimer(seconds);
        } catch (ex) {
            console.error("datetime conversion error", ex)
        }
    }

    useEffect(() => {
        if (orgMatch.last_modified <= match?.last_modified) return;
        console.log("update match time based on http", orgMatch, match)
        getSecondsFromMatch(orgMatch)
        setMatch(orgMatch)
    }, [orgMatch])


    useEffect(() => {
        if (lastMessage) {
            if (lastMessage.last_modified <= match?.last_modified) {
                console.log("old message newer", lastMessage)
                return;
            }
            const message = JSON.parse(lastMessage.data)
            getSecondsFromMatch(message)
            setMatch((oldMatch) => ({ ...oldMatch, ...message }))
            console.log("lastmessage", message)

            //console.log("liveTimer", liveTimer);
        }
    }, [lastMessage])

    // Function to start the timer
    const startTimer = () => {
        // Use setInterval to update the timer every 1000 milliseconds (1 second)
        //console.log("timerOn.current", timerOn.current)
        //if (timerOn.current) return;
        //timerOn.current = true;
        if (timerOn.current) {
            clearInterval(timerOn.current);
        }
        timerOn.current = (setInterval(() => {
            // Update the timer by incrementing the previous value by 1
            setLiveTimer((prev) => prev + 1);
        }, 1000));
    }

    // Function to pause the timer
    const pauseTimer = () => {
        // Clear the interval to stop the timer from updating
        clearInterval(timerOn.current);
        //timerOn.current = false;
    }

    if (!match) return;


    return (
        <>
            <FlooerballHeader liveTimer={liveTimer} match={match} />
            <Row>
                <div>
                    <Image
                        src={match.club_A_crest}
                        width={mobile ? 50 : 100} />
                    {match.team_A_abbrevation}
                </div>

                {match.status != 'Fixture' &&
                    <h2>
                        {match.fs_A}  - {match.fs_B}
                    </h2>
                }
                <div>
                    <Image
                        src={match.club_B_crest}
                        width={mobile ? 50 : 100} />
                    {match.team_B_abbrevation}
                </div>
            </Row>
        </>

    )
}

const FloorballMatches = ({ games, mobile, showMatch, getMatch }) => {


    return (

        <div className="container-xs space-between" style={{ maxWidth: '500' }}>
            {games &&
                games.map(match => (
                    <div key={match.match_id}>
                        <div style={{ margin: 50 }} onClick={() => { getMatch(match.match_id) }}>

                            <LiveMatch orgMatch={match} mobile={mobile} />
                        </div>
                        {(showMatch && showMatch.match_id === match.match_id) &&
                            <>
                                <FloorballLineups
                                    match={showMatch}
                                />
                                <FloorballEvents
                                    match={showMatch}
                                />
                            </>
                        }
                    </div>
                ))
            }
        </div>
    );

};

export default FloorballMatches;