import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';




export const FloorballTable = ({ data }) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    if (!data) return <CircularProgress />

    return (
        <TableContainer>
            <Table sx={{}} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow >
                        <TableCell sx={{ padding: 0 }}>#</TableCell>
                        <TableCell align="right" ><Typography>Team</Typography></TableCell>
                        <TableCell align="right">{isMobile ? "G" : "Games"}</TableCell>
                        <TableCell align="right">{isMobile ? "W" : "Wins"}</TableCell>
                        <TableCell align="right">{isMobile ? "T" : "Ties"}</TableCell>
                        <TableCell align="right">{isMobile ? "L" : "Loses"}</TableCell>
                        <TableCell align="right">{isMobile ? "P" : "Points"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow
                            key={"table" + row.current_standing + row.team_name}

                        //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{ padding: 0 }} >
                                {row.current_standing}
                            </TableCell>
                            <TableCell align="right">{row.team_name}</TableCell>
                            <TableCell align="right">{row.matches_played}</TableCell>
                            <TableCell align="right">{row.matches_won}</TableCell>
                            <TableCell align="right">{row.matches_played - row.matches_lost - row.matches_won}</TableCell>
                            <TableCell align="right">{row.matches_lost}</TableCell>
                            <TableCell align="right">{row.points}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}