import React, { useEffect } from 'react';
// import sections
import Hero from '../../components/sections/ponystories/Hero';

const Home = () => {


    return (
        <>

            <Hero className="illustration-section-01" />
            {
                //<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
            }
            {/*<Testimonial topDivider />*/}
            {/*<Cta split />*/}
        </>
    );
}

export default Home;