import React, { useRef, useEffect } from 'react';
import { useLocation, Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutProfile from './layouts/LayoutProfile';
import LayoutPrivacy from './layouts/LayoutPrivacy';
import LayoutPonystories from './layouts/LayoutPonystories';


// Views 
import Home from './views/ponystories/Home';
import Profile from './views/Profile';
import ResetPassword from './views/ResetPassword';
import Floorball from './views/Floorball';
import PrivacyPolicy from './views/PrivacyPolicy';
import UnderConstruction from './components/sections/UnderConstruction';
import { ThemeProvider, createTheme } from "@mui/material/styles";


// Initialize Google Analytics
//ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.initialize('G-REJGRVY0NZ');

const trackPage = page => {
  ReactGA.set({ page });
  //ReactGA.pageview(page);
  ReactGA.send({ hitType: "pageview", page: page, title: page });
  console.log("trackpage: ", page)
};

const themeDark = createTheme({
  palette: {
    mode: 'dark'
  },
});

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    console.log("location ", window.location.hostname)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const baseUrl = () => (window.location.hostname);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <ThemeProvider theme={themeDark}>
          <Routes>
            <Route path="floorball" element={<AppRoute component={Floorball} layout={LayoutProfile} />} />
            <Route path="*" element={<AppRoute component={Home} layout={LayoutPonystories} />} />


            {/*
          <AppRoute path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/profile/:name" component={Profile} layout={LayoutProfile} />
          <AppRoute exact path="/reset-password" component={ResetPassword} layout={LayoutProfile} />
          <AppRoute exact path="/floorball" component={Floorball} layout={LayoutProfile} />
          
      */}
          </Routes>
        </ThemeProvider>
      )} />
  );
}

export default App;