import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../Image';
import Row from '../Row';
import FlooerballHeader from './FloorballHeader';

const FloorballMatchesSimple = ({ games, mobile }) => {


    return (

        <div className="container-xs space-between" style={{ maxWidth: '500' }}>
            {games &&
                games.map(match => (
                    <div key={match.match_id} style={{ margin: 30 }}>
                        <FlooerballHeader match={match} />
                        <Row>
                            <div>

                                {match.team_A_abbrevation}
                            </div>

                            {match.status != 'Fixture' &&
                                <div>
                                    {match.fs_A}  - {match.fs_B}
                                </div>
                            }
                            <div>

                                {match.team_B_abbrevation}
                            </div>
                        </Row>
                    </div>
                ))
            }
        </div>
    );

};

export default FloorballMatchesSimple;