import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import Button from '../../elements/Button';
import ButtonGroup from '../../elements/ButtonGroup';
import { Link } from 'react-router-dom';


const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Hero = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const [videoModalActive, setVideomodalactive] = useState(false);



    const openModal = (e) => {
        e.preventDefault();
        setVideomodalactive(true);
    }

    const closeModal = (e) => {
        e.preventDefault();
        setVideomodalactive(false);
    }

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            Welcome to <span className="text-color-primary">PonyStories Group</span>
                        </h1>
                        <div className="container-xs">
                            <div className="reveal-from-bottom" data-reveal-delay="600">
                                {/*
                                <ButtonGroup>
                                    <Link to='https://ponyshare.app' style={{ margin: 20 }}>
                                        <Button>PonyShare App</Button>
                                    </Link>
                                    <Link to='https://ponyshare.app/floorball' style={{ margin: 20 }}>
                                        <Button>Floorball</Button>
                                    </Link>
                                </ButtonGroup>
*/
                                }
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;