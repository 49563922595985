import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import { useParams, useLocation } from "react-router-dom";
import '../../App.css';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
//import profiles from '../../assets/profiles.json'

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const ResetPasswordSection = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const { search } = useLocation();

    const [decodedToken, setDecodedToken] = useState("");
    const [email, setEmail] = useState("");
    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");
    const [emailHint, setEmailHint] = useState("");
    const [pass1Hint, setPass1Hint] = useState("");
    const [pass2Hint, setPass2Hint] = useState("");

    const [bannerOpen, setBannerOpen] = useState(false);
    const [bannerText, setBannerText] = useState("");
    const [bannerStyle, setBannerStyle] = useState("banner-link-red");

    useEffect(() => {

        const searchParams = new URLSearchParams(search);
        const decoded = decodeURIComponent(searchParams.get("token"))
        setDecodedToken(decoded)
        console.log("decoded token: ", decoded)
        const api = axios.create({
            baseURL: 'https://ponyshareapi.azurewebsites.net',
        })
        api.get('api/auth/echo')
    }, [])

    const passwordReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{6,}$/

    const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    const validate = () => {
        if (!emailReg.test(email)) return false;
        if (email === "") {
            return false;
        }

        if (pass1 != pass2) {
            return false;
        }
        return true;
    }

    const setPass1Func = (text) => {
        setPass1(text);
        if (!passwordReg.test(text)) {
            setPass1Hint("Password must be 6 characters long and include lowercase and capital letter, digit and special character (#?!@$%^&*-.)");
        } else {
            setPass1Hint("");
        }
    }

    const setPass2Func = (text) => {
        setPass2(text);
        if (pass1 != text) {
            setPass2Hint("Passwords are not the same");
        } else {
            setPass2Hint("");
        }

    }

    const setEmailFunc = (text) => {
        setEmail(text);
        if (!emailReg.test(text)) {
            setEmailHint("Not valid email");
        } else {
            setEmailHint("");
        }
    }

    const sendReset = () => {


        if (!emailReg.test(email)) {
            setEmailHint("Not valid email");
            return false;
        } else {
            setEmailHint("");
        }

        if (!passwordReg.test(pass1)) {
            setPass1Hint("Password must be at least 6 characters long and include lowercase and capital letter, digit and special character (#?!@$%^&*-.)");
            return;
        } else {
            setPass1Hint("");
        }
        if (pass1 != pass2) {
            setPass2Hint("Passwords are not the same");
            return;
        } else {
            setPass2Hint("");
        }

        var body = {
            password1: pass1,
            password2: pass2,
            username: email,
            token: decodedToken
        }
        setEmail("");
        setPass1("");
        setPass2("");

        const api = axios.create({
            baseURL: 'https://ponyshareapi.azurewebsites.net',
        })
        api.post('api/auth/reset-password', body)
            .then(function (response) {
                console.log(response);
                setBannerStyle("banner-link-green")
                setBannerText("Password changed! Now you can login in PonyShare application!")
                setBannerOpen(true);
                if (isMobile) {
                    window.open('ponyshare://login');
                }

            })
            .catch(function (error) {
                console.log(error);
                setBannerStyle("banner-link-red")
                setBannerText("Password change failed!")
                setBannerOpen(true)
            });
    }


    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className="hero-inner ">
                    <div className="hero-content">
                        <Image
                            style={{ marginBottom: 20 }}
                            className="has-shadow"
                            src={require('./../../assets/images/logo_transparent_background.png')}
                            alt="Hero"
                            width={896}
                            height={504} />
                        <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{ marginBottom: 20 }}>
                            Reset Password
                        </h2>

                        <div className="container-xs space-between" style={{ maxWidth: 500 }}>
                            <Input
                                type="email"
                                labelHidden
                                placeholder="Email"
                                style={{ marginBottom: 20 }}
                                value={email}
                                onChange={e => setEmailFunc(e.target.value)}
                                hint={emailHint}
                            />
                            <Input
                                type="password"
                                labelHidden
                                placeholder="New password"
                                style={{ marginBottom: 20 }}
                                value={pass1}
                                onChange={e => setPass1Func(e.target.value)}
                                hint={pass1Hint}
                            />
                            <Input
                                type="password"
                                labelHidden
                                placeholder="Re-type new password"
                                style={{ marginBottom: 20 }}
                                value={pass2}
                                onChange={e => setPass2Func(e.target.value)}
                                hint={pass2Hint}
                            />

                            <Button
                                color="dark"
                                style={{ marginTop: 20 }}
                                disabled={!validate()}
                                onClick={sendReset}
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {bannerOpen && (
                <div {...props} className="banner">
                    <div className="banner-inner text-xxs">
                        <div className='banner-container'>
                            <a className={bannerStyle} rel="noopener noreferrer">
                                {bannerText}
                            </a>
                        </div>
                        <button className="banner-close" onClick={() => setBannerOpen(false)}>
                            <span className="screen-reader">Close</span>
                            <svg viewBox="0 0 16 16">
                                <path d="M12.72 3.293a1 1 0 00-1.415 0L8.012 6.586 4.72 3.293a1 1 0 00-1.414 1.414L6.598 8l-3.293 3.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L9.426 8l3.293-3.293a1 1 0 000-1.414z" />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </section >
    );
}

ResetPasswordSection.propTypes = propTypes;
ResetPasswordSection.defaultProps = defaultProps;

export default ResetPasswordSection;