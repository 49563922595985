import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );
  /*
  return (
    <div className="footer-copyright">Contact: <a href="mailto:info.ponyshare@ponystories.com">info.ponyshare@ponystories.com</a></div>
  );
  */

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          {
            //         <a href="https://ponyshare.app/privacy_policy.html" target="_blank" >Privacy Policy</a>
          }
          <Link to='privacypolicy'>Privacy Policy</Link>
        </li>
        <li>
          <a href="https://ponyshare.app/terms.html" target="_blank" >Terms & Conditions</a>
        </li>
        <li>
          <a href="https://ponyshare.app/eula.html" target="_blank" >User Agreement</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;