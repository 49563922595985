import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../Image';
import Row from '../Row';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

const useRefs = () => {
    const refsByKey = useRef({})

    const setRef = (element, key) => {
        refsByKey.current[key] = element;
    }

    return { refsByKey: refsByKey.current, setRef };
}

const FloorballEvents = ({ match }) => {
    const { refsByKey, setRef } = useRefs()
    const wordsRef = useRef([]);

    const refs = Object.values(refsByKey).filter(ref => true)
    useEffect(() => {
        const l = match?.events?.filter(event => event.code === 'syotto').map(event => {
            refsByKey[event.connected_event_id].innerText = event.player_name + ' #' + event.shirt_number
        })
    }, [refsByKey])


    if (match?.events?.length === 0) {
        return (
            <div style={{ maxWidth: '500' }}>
                No Events
            </div>
        );
    };




    return (

        <div style={{ maxWidth: '500' }}>
            <Timeline position="alternate">
                {match?.events?.filter(event => event.code === '2min' || event.code === 'maali' || event.code === '5min').map(event => {
                    //if (event.code != '2min' && event.code != 'maali' && event.code != '5min' && event.code != 'syotto') return;
                    /*
                    return (
                        <Row key={event.event_id} style={event.team === 'B' ? { alignItems: 'right' } : {}}>
                            <div style={{ flex: '0.4' }}>

                                {event.code_fi}
                            </div>
                            <div style={{ flex: '0.2' }}>
                                {event.time}
                            </div>
                            <div style={{ flex: '0.4' }}>
                                {event.player_name} {event.shirt_number}
                            </div>
                        </Row>
                    )
                    */

                    return (

                        <TimelineItem key={event.event_id} position={event.team === 'A' ? 'left' : 'right'}>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                            //color="text.primary"
                            >
                                <div>
                                    {event.code === 'maali' &&
                                        //(event.s_A ? event.s_A : '0') + ' - ' + (event.s_B ? event.s_B : '0')
                                        event.description
                                    }
                                </div>
                                <div>
                                    {event.time}
                                </div>

                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }} >
                                <Typography variant="h6" component="span" color={event.code === '2min' && 'red'} >
                                    {event.code === 'maali' ?
                                        "Maali" : event.code_fi
                                    }
                                </Typography>
                                <Typography variant="body1">{event.player_name} #{event.shirt_number}</Typography>
                                <Typography ref={element => setRef(element, event.event_id)} variant="body1" style={{ fontSize: 14 }} />
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
                }
            </Timeline>
        </div >
    );

};

export default FloorballEvents;